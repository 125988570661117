.o-filter-button {
  position: relative;
  display: inline-block;
  transition: transform 300ms $ease-out, opacity 200ms $ease-fade;
  opacity: 0.4;

  &::after {
    @include extended-clickarea;
  }

  // Underline
  // &::before {
  //   @include underline;
  //   @include underline-inactive;
  // }

  &:hover {
    opacity: 1;

    // &::before {
    //   @include underline-active;
    // }
  }
  &--active,
  &:focus {
    opacity: 1;
  }

  &:active {
    transform: scale(0.95);
  }
}
