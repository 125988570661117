.l-services-list {
  max-width: 1000/16 * 1rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.l-services-list__item {
  text-align: center;
  width: 31%;

  div {
    margin-left: auto;
    margin-right: auto;

    @media ($max-s) {
      width: 75%;
    }

    @media ($min-s) {
      max-width: 100/16 * 1rem;
    }
  }

  svg {
    width: 100%;
    height: auto;
  }

  p {
    margin-top: 1rem;
    font-weight: 200;

    @include poly-fluid-sizing(
      'font-size',
      (
        $xs: nth($font-size-small, 1),
        $s: nth($font-size-default, 1),
        $ml: nth($font-size-intro, 2)
      )
    );
  }
}
