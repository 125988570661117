::selection {
  background-color: $color-black;
  color: $color-white;
}

html {
  background-color: $color-gray-light;
  color: $color-black;
  font-family: $font-family-default;
  font-size: 1em;
  line-height: $line-height-default;
  letter-spacing: $letter-spacing-default;
  font-variant: no-common-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // prevent long word wrap
  @media ($max-s) {
    hyphens: auto;
  }
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-default, 1),
      $ml: nth($font-size-default, 2)
    )
  );
}

main {
  flex-grow: 1;
}
