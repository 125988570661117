.o-hl1 {
  font-weight: 200;

  @include poly-fluid-sizing(
    'font-size',
    (
      $xxs: nth($font-size-intro, 1) * 0.9,
      $xs: nth($font-size-hl1, 1),
      $s: nth($font-size-hl1, 1),
      $ml: nth($font-size-hl1, 2)
    )
  );
}

.o-hl2 {
  @media ($min-s) {
    font-weight: 200;
  }

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-hl2, 1),
      $ml: nth($font-size-hl2, 2)
    )
  );
}

.o-hl3 {
  font-weight: bold;
}
