::selection {
  text-shadow: none;
}

html {
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
}

ul,
ol,
dl,
dd,
dt,
p {
  margin: 0;
  padding: 0;
}

ul li {
  padding: 0;
  list-style-type: none;
}

figure {
  margin: 0;
}

figcaption {
  font-weight: inherit;
}

img,
video {
  width: 100%;
  height: auto;
  vertical-align: top;
}

video {
  outline: 0;
}

iframe {
  border: none;
}

svg {
  vertical-align: top;
}

blockquote {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;

  // Fix FF dotted outline bug on Windows?
  // &:hover,
  // &:active {
  //   outline-width: 0;
  // }
}

button {
  border: 0;
  cursor: pointer;
  color: inherit;
  background: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

// hide outline without hurting a11y
main,
button {
  .js-focus-visible &:focus:not(.focus-visible) {
    outline: none;
  }
}

// a11y
*,
*::before,
*::after {
  @media (prefers-reduced-motion: reduce) {
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important;
  }
}
