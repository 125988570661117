// .c-nav {
// }

.c-nav__toggle {
  box-sizing: content-box;
  width: 32/16 * 1rem;
  height: 32/16 * 1rem;
  padding: 0.5rem;
  position: relative;
  z-index: 10;
  right: -0.5rem;
  top: -0.25rem;

  @media ($min-s) {
    top: -0.125rem;
  }

  @media ($min-m) {
    display: none;
  }
}

.c-nav__wrapper {
  position: relative;

  @media ($max-m) {
    padding-left: $base-padding-x-m;
    padding-right: $base-padding-x-m;
    padding-top: 2.5rem;
    padding-bottom: 3rem;
    position: absolute;
    z-index: 8;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100vh;
    background-color: $color-white;
    box-shadow: 0 2px 20px rgba($color-black, 0.2);
    display: flex;
    flex-direction: column;
    visibility: hidden; // A11Y: Prevent focus if nav is hidden
    opacity: 0;

    &--active {
      opacity: 1;
      visibility: visible;
    }

    // separate class to prevent resize flicker
    &--anim {
      transition: opacity 300ms $ease-fade, visibility 0ms linear 300ms;

      &.c-nav__wrapper--active {
        transition-delay: 0ms, 0ms;
      }
    }
  }
}

.c-nav__list {
  &--primary {
    @media ($max-m) {
      font-size: 24/16 * 1rem;

      > * + * {
        margin-top: 0.875rem;
      }
    }

    @media ($min-m) {
      font-size: 18/16 * 1rem;
      margin-top: 0.25rem;
    }
  }

  &--secondary {
    margin-top: 2.25rem;
    color: rgba($color-black, 0.33);

    @media ($max-m) {
      font-size: 20/16 * 1rem;

      > * + * {
        margin-top: 0.5rem;
      }
    }

    @media ($min-m) {
      display: none;
    }
  }
}

.c-nav__item {
  @media ($min-m) {
    display: inline-block;
    margin-left: 1.75rem;

    &--hidden {
      display: none;
    }
  }
}

.c-nav__sub-list {
  @media ($max-m) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 1.5rem;

    > * + * {
      margin-top: 0.125rem;
    }
  }

  @media ($min-m) {
    display: none;
  }
}

.c-nav__lang {
  @media ($max-m) {
    margin-top: 2.25rem;
    order: 1;
  }

  @media ($min-m) {
    font-size: 13/16 * 1rem;
    position: absolute;
    top: -3rem;
    right: 0;
  }

  > * {
    display: inline-block;

    @media ($max-m) {
      font-size: 18/16 * 1rem;
    }

    & + * {
      margin-left: 1rem;

      @media ($min-m) {
        margin-left: 0.875rem;
      }
    }
  }
}

// Animation
@media ($max-m) {
  .c-nav__list--primary > *,
  .c-nav__list--secondary,
  .c-nav__lang {
    transition: opacity 100ms $ease-fade, transform 500ms $ease-out;
    opacity: 0;
    transform: translateY(2rem);

    .c-nav__wrapper--active & {
      transition: opacity 300ms $ease-fade, transform 800ms $ease-out;
      opacity: 1;
      transform: translateY(0);
    }
  }

  // Delays
  .c-nav__wrapper--active {
    .c-nav__list--primary > * {
      @for $i from 1 through 7 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 50ms;
        }
      }
    }

    .c-nav__list--secondary {
      transition-delay: 6 * 50ms;
    }

    .c-nav__lang {
      transition-delay: 7 * 50ms;
    }
  }
}
