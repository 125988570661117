.c-cookie-consent {
  position: fixed;
  z-index: 20;
  bottom: 0.5rem;
  right: 0.5rem;
  margin-left: 0.5rem;
  font-size: 13/16 * 1rem;
  padding: 10/16 * 1rem $base-padding-x-s 10/16 * 1rem;
  background-color: $color-white;
  color: rgba($color-black, 0.5);
  box-shadow: 0 2px 5px rgba($color-black, 0.1);
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms $ease-fade, visiblity 0ms linear;

  &--visible {
    opacity: 1;
    visibility: visible;
  }
}

.c-cookie-consent__button {
  padding: 0.75rem;
  margin: -0.75rem;
  margin-left: 0.5rem;
}
