.o-map {
  height: 50vw;
  max-height: 550/16 * 1rem;
  min-height: 350/16 * 1rem;
}

.o-map__wrapper {
  height: 100%;
  background-color: rgba($color-black, 0.033);
}

.o-map__marker {
  width: 34px;
  height: 52px;
  display: none;

  // Only show if Mapbox has initialized the marker
  &[style] {
    display: block;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  ellipse {
    fill: $color-black;
    opacity: 0.1;
  }

  path {
    fill: $color-black;
  }

  circle {
    fill: $color-white;
  }
}
