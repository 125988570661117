.c-contact {
}

.c-contact__address {
  margin-bottom: 2rem;
}

.c-contact__route {
  margin-top: 2rem;
}
