.l-image-grid {
  @media ($min-ms) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -3rem;
  }
}

.l-image-grid__item {
  margin-top: 6rem;

  @media ($min-ms) {
    margin-top: 9rem;
  }

  // Widths
  &--w100 {
    width: 100%;
  }

  &--w80 {
    @media ($min-ms) {
      width: 80%;
    }
  }

  &--w50,
  &--w30 {
    @media ($min-ms) {
      width: 47%;
      width: calc(50% - #{$base-padding-x-m * 0.5});
    }

    @media ($min-ml) {
      width: calc(50% - #{$base-padding-x-l * 0.5});
    }
  }

  &--w30 {
    @media ($min-ms) {
      > * {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  // Align
  &--align-bottom {
    @media ($min-ms) {
      align-self: flex-end;
    }
  }

  // Offset
  &--offset-top {
    @media ($min-ms) {
      margin-top: 6rem;
    }
  }

  &--offset-bottom {
    @media ($min-ms) {
      margin-bottom: 6rem;
    }
  }
}
