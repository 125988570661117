.l-wrapper {
  @include base-padding-x;
  max-width: $max-width-base;
  margin-left: auto;
  margin-right: auto;

  &--hero {
    @media ($max-s) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--full {
    padding-left: 0;
    padding-right: 0;

    // @media ($min-l) {
    //   padding-left: $base-padding-x-l;
    //   padding-right: $base-padding-x-l;
    // }
  }
}
