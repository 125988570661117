.l-projects-list {
  @media ($min-ms) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: -6rem; // b/c filter
  }
}

.l-projects-list__item {
  // margins
  @media ($max-ms) {
    & + & {
      margin-top: 4rem;
    }
  }

  @media ($min-ms) {
    margin-bottom: 6rem; // b/c filter
  }

  // widths
  @media ($min-ms) {
    width: 47%;
    width: calc(50% - #{$base-padding-x-m * 0.5});
  }

  @media ($min-ml) {
    width: calc(50% - #{$base-padding-x-l * 0.5});
  }
}
