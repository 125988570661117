// Paddings
.u-px--base {
  @include base-padding-x;
}

.u-pt {
  &--m {
    padding-top: 4rem;

    @media ($min-ms) {
      padding-top: 5rem;
    }
  }

  &--l {
    padding-top: 6rem;

    @media ($min-ms) {
      padding-top: 7rem;
    }
  }
}

.u-pb {
  &--base {
    padding-bottom: 7rem;

    @media ($min-ms) {
      padding-bottom: 8rem;
    }
  }
  &--m {
    padding-bottom: 4rem;

    @media ($min-ms) {
      padding-bottom: 5rem;
    }
  }
  &--l {
    padding-bottom: 6rem;

    @media ($min-ms) {
      padding-bottom: 7rem;
    }
  }
}

// Margins
.u-mt {
  &--s {
    margin-top: 1rem;

    @media ($min-ms) {
      margin-top: 1.5rem;
    }
  }
  &--ms {
    margin-top: 2rem;

    @media ($min-ms) {
      margin-top: 2.5rem;
    }
  }
  &--m {
    margin-top: 4rem;

    @media ($min-ms) {
      margin-top: 5rem;
    }
  }
  &--l {
    margin-top: 6rem;

    @media ($min-ms) {
      margin-top: 7rem;
    }
  }
}

.u-mx--auto {
  margin-left: auto;
  margin-right: auto;
}

.u-ml--auto {
  margin-left: auto;
}

// Widths (i.e. 1/2 column)
.u-w {
  &--s {
    @media ($min-ms) {
      width: 47%;
      width: calc(50% - #{$base-padding-x-m * 0.5});
    }

    @media ($min-ml) {
      width: calc(50% - #{$base-padding-x-l * 0.5});
    }
  }

  &--m {
    @media ($min-ms) {
      width: 80%;
    }
  }
}

// BG colors
.u-bg--white {
  background-color: $color-white;
}

// Text align
.u-ta--center {
  text-align: center;
}

// –––

// Helper classes
.u-text-spacing {
  // Default
  > * + * {
    margin-top: 1.5rem;
  }

  // Headlines, Intro
  > .o-hl1 + *,
  > .o-intro + * {
    margin-top: 2.5rem;
  }
  > .o-hl2 + * {
    margin-top: 1rem;
  }
  > .o-hl3 + * {
    margin-top: 0.5rem;
  }

  > * + .o-hl1,
  > * + .o-hl2 {
    margin-top: 5rem;
  }
  > * + .o-hl3 {
    margin-top: 3rem;
  }

  > .o-hl1 + .o-hl2 {
    margin-top: 3rem;
  }
  > .o-hl2 + .o-hl3 {
    margin-top: 2.5rem;
  }
  > .o-intro + .o-more-link {
    margin-top: 1.5rem;
  }

  // Category
  > .o-category + * {
    margin-top: 1.25rem;
  }

  // Other elements
  > .o-quote + * {
    margin-top: 3.5rem;
  }
  > .o-figure + * {
    margin-top: 5rem;
  }

  > * + .o-quote {
    margin-top: 4rem;
  }
  > * + .o-figure {
    margin-top: 5rem;
  }
}

// A11y
.u-a11y-hidden {
  // VO (Mac) bug not fixed: https://github.com/h5bp/html5-boilerplate/issues/1985
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
