.c-team-member {
}

.c-team-member__name {
  margin-top: 1.25rem;
}

.c-team-member__position {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
