.o-link {
  position: relative;
  display: inline-block;
  transition: transform 300ms $ease-out, opacity 200ms $ease-fade;

  // Extend clickable area without affecting underline position
  &--extended::after {
    @include extended-clickarea;
  }

  &--lang {
    text-transform: uppercase;
    opacity: 0.33;

    &::after {
      @include extended-clickarea;
    }

    &::before {
      display: none;
    }
  }

  // Underline
  &::before {
    @include underline;
    @include underline-inactive;
  }

  &:hover,
  &:focus,
  &--active {
    opacity: 1;

    &::before {
      @include underline-active;
    }
  }

  &--sub-active {
    &::before {
      @media ($max-m) {
        @include underline-inactive;
      }
    }
  }

  &:active {
    transform: scale(0.95);
  }
}
