.o-category {
  text-transform: uppercase;
  letter-spacing: $letter-spacing-wide;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-small, 1),
      $ml: nth($font-size-small, 2)
    )
  );
}
