.l-header {
  @include base-padding-x;
  padding-top: 1.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: $max-width-base;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media ($min-s) {
    padding-top: 2rem;
    padding-bottom: 3.25rem;
  }

  @media ($min-m) {
    padding-top: 3rem;
    padding-bottom: 4.25rem;
  }
}

.l-header__logo {
  display: block;
  width: 347/16 * 1rem * 0.75;
  height: 34/16 * 1rem * 0.75;
  margin-right: 1rem;

  @media ($min-s) {
    width: 347/16 * 1rem;
    height: 34/16 * 1rem;
  }
}
