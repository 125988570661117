@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes move-in {
  from {
    transform: translateY(3em); // Safari does not like rem...
  }
  to {
    transform: translateY(0);
  }
}

.u-anim--in {
  animation: fade-in 200ms $ease-fade both, move-in 2000ms $ease-out both;
  animation-play-state: paused;

  .no-js & {
    animation-play-state: running;
  }
}

@for $i from 1 through 7 {
  .u-anim--delay-#{$i} {
    animation-delay: $i * 200ms;
  }
}

.u-anim--play {
  animation-play-state: running;
}
