.l-columns {
  @media ($min-ms) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.l-columns__item {
  // margins
  @media ($max-ms) {
    & + & {
      margin-top: 4rem;
    }
  }

  @media ($min-ms) {
    &:nth-child(n + 3) {
      margin-top: 6rem;
    }
  }

  // widths
  @media ($min-ms) {
    width: 47%;
    width: calc(50% - #{$base-padding-x-m * 0.5});
  }

  @media ($min-ml) {
    width: calc(50% - #{$base-padding-x-l * 0.5});
  }

  // offset
  .l-columns--offset & {
    @media ($max-ms) {
      & + .l-columns__item {
        margin-top: 3rem;
      }
    }

    @media ($min-ms) {
      &:nth-child(odd) {
        margin-top: 24%;
      }
    }
  }

  .l-columns--service &:nth-child(1) {
    @media ($max-ms) {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
