.o-text-link {
  text-decoration: underline;
  text-decoration-color: rgba($color-black, 0.33);
  transition: color 150ms $ease-fade, text-decoration-color 150ms $ease-fade;

  &:hover,
  &:focus {
    text-decoration-color: $color-black;
    color: rgba($color-black, 0.5);
  }

  &:active {
    color: inherit;
  }
}
