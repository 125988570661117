// Colors
$color-white: rgb(255, 255, 255);
$color-black: rgb(0, 0, 0);
$color-gray-light: rgb(243, 243, 243);
$color-gray-dark: rgb(170, 170, 170);

// Breakpoints
$xxs: 320 / 16 * 1em;
$xs: 360 / 16 * 1em;
$s: 480 / 16 * 1em;
$ms: 720 / 16 * 1em;
$m: 960 / 16 * 1em;
$ml: 1200 / 16 * 1em;
$l: 1440 / 16 * 1em;
$xl: 1680 / 16 * 1em;
$xxl: 1920 / 16 * 1em;

$max-xxs: 'max-width: #{$xxs - (1/16)}';
$min-xxs: 'min-width: #{$xxs}';
$max-xs: 'max-width: #{$xs - (1/16)}';
$min-xs: 'min-width: #{$xs}';
$max-s: 'max-width: #{$s - (1/16)}';
$min-s: 'min-width: #{$s}';
$max-ms: 'max-width: #{$ms - (1/16)}';
$min-ms: 'min-width: #{$ms}';
$max-m: 'max-width: #{$m - (1/16)}';
$min-m: 'min-width: #{$m}';
$max-ml: 'max-width: #{$ml - (1/16)}';
$min-ml: 'min-width: #{$ml}';
$max-l: 'max-width: #{$l - (1/16)}';
$min-l: 'min-width: #{$l}';
$max-xl: 'max-width: #{$xl - (1/16)}';
$min-xl: 'min-width: #{$xl}';
$max-xxl: 'max-width: #{$xxl - (1/16)}';
$min-xxl: 'min-width: #{$xxl}';

// Fonts
$font-family-default: 'Raleway Web', -system-ui, -apple-system,
  BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

// Font sizes
$font-size-default: (1rem, 18/16 * 1rem);
$font-size-small: (13/16 * 1rem, 14/16 * 1rem);
$font-size-intro: (21/16 * 1rem, 26/16 * 1rem);
$font-size-quote: (21/16 * 1rem, 26/16 * 1rem);
$font-size-hl1: (21/16 * 1rem, 26/16 * 1rem);
$font-size-hl2: (18/16 * 1rem, 22/16 * 1rem);

// Line heights
$line-height-default: 1.5;

// Letter spacings
$letter-spacing-default: 0.055em;
$letter-spacing-wide: 0.075em;

// Paddings
$base-padding-x-s: 1rem;
$base-padding-x-m: 2.5rem;
$base-padding-x-l: 4.25rem;

// Max widths
$max-width-base: 1440/16 * 1rem;
$max-width-article: 800/16 * 1rem;

// Easings
$ease-out: cubic-bezier(0.23, 1, 0.32, 1);
$ease-fade: cubic-bezier(0, 0, 0.25, 1);
