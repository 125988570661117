.c-project-teaser {
  position: relative;
  transition: opacity 500ms $ease-fade;
  opacity: 1;

  &--fade {
    opacity: 0;
    transition: opacity 200ms $ease-fade;
  }

  &--hidden {
    display: none;
  }

  &:hover img,
  &:focus-within img {
    transform: scale(1.05);
  }
}

// with more link below
.c-project-teaser__text {
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
}

.c-project-teaser__hl {
  margin-top: 1.25rem;
  font-size: nth($font-size-default, 1);
}

.c-project-teaser__link {
  &::after {
    @include extended-clickarea;
    top: 0;
    padding: 0;
    z-index: 2;
  }

  span {
    display: block;
  }
}

.c-project-teaser__overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $color-white;
  background-color: rgba($color-black, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  // text-transform: lowercase;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-default, 1) * 1.25,
      $ml: nth($font-size-default, 2) * 1.25
    )
  );

  a {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
