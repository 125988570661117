.l-partners-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(#{75/16 * 1rem}, 1fr));
    grid-column-gap: 2.5rem;
    grid-row-gap: 2.5rem;

    @media ($min-ms) {
      grid-template-columns: repeat(auto-fill, minmax(#{125/16 * 1rem}, 1fr));
      grid-column-gap: 5rem;
      grid-row-gap: 4rem;
    }
  }
}

.l-partners-list__item {
  width: 12.5%;
  margin-right: 7.5%;
  margin-bottom: 3rem;

  @supports (display: grid) {
    width: auto;
    margin-right: 0;
    margin-bottom: 0;
  }
}
