.c-projects-filter {
  display: flex;
  justify-content: space-between;
  font-size: nth($font-size-default, 1);
}

.c-projects-filter__list {
  margin-top: -0.375rem;
  // @media ($max-ms) {
  //   display: none;
  // }

  li {
    display: inline-block;
    margin-right: 1.3125rem;
    margin-top: 0.375rem;

    @media ($min-s) {
      margin-right: 1.5rem;
    }
  }
}

// .c-projects-filter__select {
//   width: 150/16 * 1rem;

//   @media ($min-ms) {
//     display: none;
//   }
// }

.c-projects-filter__nav {
  margin-left: 3rem;

  @media ($max-s) {
    display: none;
  }
}
