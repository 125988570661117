.o-more-link {
  position: relative;
  display: inline-block;
  font-size: nth($font-size-default, 1);
  line-height: 1.4;

  // Extend clickable area without affecting underline position
  &::after {
    @include extended-clickarea;
  }

  &--extended {
    position: static;

    &::after {
      top: 0;
      padding: 0;
      z-index: 2;
    }
  }

  // Underline
  & > span {
    position: relative;
    display: inline-block;
    transition: transform 300ms $ease-out, opacity 200ms $ease-fade;
    opacity: 0.4;

    &::before {
      @include underline;
      @include underline-inactive;
    }
  }

  &:hover,
  &:focus {
    & > span {
      opacity: 1;

      &::before {
        @include underline-active;
      }
    }
  }

  &:active > span {
    transform: scale(0.95);
  }
}
