.l-footer {
  background-color: $color-gray-dark;
  color: $color-white;
  font-size: 16/16 * 1rem;
}

.l-footer__wrapper {
  @include base-padding-x;
  padding-top: 3.25rem;
  padding-bottom: 3rem;
  max-width: $max-width-base;
  margin-left: auto;
  margin-right: auto;

  @media ($max-ms) {
    > * + * {
      margin-top: 2.5rem;
    }
  }

  @media ($min-ms) {
    font-size: 14/16 * 1rem;
    padding-top: 3.75rem;
    padding-bottom: 3.5rem;
    display: flex;
    justify-content: space-between;
  }
}

.l-footer__logo {
  display: block;
  width: 220/16 * 1rem * 0.75;
  height: 34/16 * 1rem * 0.75;

  @media ($min-s) {
    width: 220/16 * 1rem;
    height: 34/16 * 1rem;
  }
}

.l-footer__nav {
  li + li {
    margin-top: 0.25rem;
  }
}
