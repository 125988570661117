.o-quote {
  quotes: '\00BB''\00AB';

  p {
    font-weight: 200;

    @include poly-fluid-sizing(
      'font-size',
      (
        $xxs: nth($font-size-intro, 1) * 0.9,
        $xs: nth($font-size-quote, 1),
        $s: nth($font-size-quote, 1),
        $ml: nth($font-size-quote, 2)
      )
    );
  }
}

.o-quote p::before {
  content: open-quote;
}

.o-quote p::after {
  content: close-quote;
}
