.o-figure {
  // Necessary for flexbox layouts, otherwise height == 0
  width: 100%;
  outline: 1px solid transparent; // Fix artefacts in Safari
}

.o-figure__wrapper {
  height: 0;
  overflow: hidden;
  position: relative;

  // Art directed images
  // <Picture> with inline --ratio vars for intrinsic size
  // would be better, but no IE support. Also iframe...
  &--small {
    @media ($min-s) {
      display: none;
    }
  }

  &--large {
    @media ($max-s) {
      display: none;
    }
  }

  // bg, animate opacity instead of bg-color for better perf
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color-black, 0.033);
    transition: opacity 400ms $ease-fade;
    z-index: 0;
  }

  &--transparent::before {
    opacity: 0;
  }

  // Position img / video / iframe
  > * {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }
}

.o-figure__caption {
  padding-top: 13/16 * 1rem;
}

// lazyload
.lazyload,
.lazyloading {
  opacity: 0;

  .o-figure--hero & {
    transform: scale(1.075);
  }
}

.lazyloaded {
  transition: opacity 500ms $ease-fade 0ms, transform 2000ms $ease-out 0ms;
  opacity: 1;
  backface-visibility: hidden; // Fixes position shift after transition

  .o-figure--hero & {
    transform: scale(1);
  }
}

.no-js .lazyload {
  display: none;
}
