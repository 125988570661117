.o-intro {
  font-weight: 200;

  @include poly-fluid-sizing(
    'font-size',
    (
      $xxs: nth($font-size-intro, 1) * 0.9,
      $xs: nth($font-size-intro, 1),
      $s: nth($font-size-intro, 1),
      $ml: nth($font-size-intro, 2)
    )
  );
}
